<template>
  <div>
    <div class="f-sb" style="position: relative">
      <div v-show="false">
        {{ flag }}
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-if="schoolList.c"
          :label="`冲刺（${schoolList.c.length || 0}）`"
          name="first"
        >
          <div class="f-sb df-wrap">
            <el-card
              shadow="hover"
              style="width: 440px; margin-bottom: 30px"
              v-for="(item, index) in schoolList.c"
              :key="index"
            >
              <div class="f-sb school">
                <div style="width: 80%" class="f-sb cont-1">
                  <div style="width: 30%; overflow: hidden" class="">
                    <p style="font-weight: bold">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.collegeName"
                        placement="top-start"
                      >
                        <span>{{ item.collegeName }}</span>
                      </el-tooltip>
                    </p>
                    <!-- <p>{{ item.specialtyName }}</p> -->
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.specialtyName"
                      placement="top-start"
                    >
                      <span> {{ item.specialtyName }}</span>
                    </el-tooltip>
                  </div>
                  <div style="width: 30%; overflow: hidden">
                    <p>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.collegeCode"
                        placement="top-start"
                      >
                        <span> 代码:{{ item.collegeCode }}</span>
                      </el-tooltip>
                    </p>
                    <span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.specialtyCode"
                        placement="top-start"
                      >
                        <span> 代码：{{ item.specialtyCode }}</span>
                      </el-tooltip>
                    </span>
                  </div>
                  <div style="width: 39%; overflow: hidden">
                    <p>
                      {{ item.disciplineAmbit }}
                    </p>
                    <span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="`上年最低分数：${item.scoreLowest}`"
                        placement="top-start"
                      >
                        <span> 上年最低分数：{{ item.scoreLowest }}</span>
                      </el-tooltip>
                    </span>
                  </div>
                </div>
                <div>
                  <el-button @click="choose(item, index)" round>{{
                    item.hasAdd == true ? "已填报" : "填报"
                  }}</el-button>
                </div>
              </div>
            </el-card>
          </div>
        </el-tab-pane>
        <el-tab-pane
          :label="`稳定（${schoolList.w.length || 0}）`"
          name="second"
        >
          <div class="f-sb df-wrap">
            <el-card
              shadow="hover"
              style="width: 440px; margin-bottom: 30px"
              v-for="(item, index) in schoolList.w"
              :key="index"
            >
              <div class="f-sb school">
                <div style="width: 80%" class="f-sb cont-1">
                  <div style="width: 30%; overflow: hidden" class="">
                    <p style="font-weight: bold">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.collegeName"
                        placement="top-start"
                      >
                        <span>{{ item.collegeName }}</span>
                      </el-tooltip>
                    </p>
                    <!-- <p>{{ item.specialtyName }}</p> -->
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.specialtyName"
                      placement="top-start"
                    >
                      <span> {{ item.specialtyName }}</span>
                    </el-tooltip>
                  </div>
                  <div style="width: 30%; overflow: hidden">
                    <p>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.collegeCode"
                        placement="top-start"
                      >
                        <span> 代码:{{ item.collegeCode }}</span>
                      </el-tooltip>
                    </p>
                    <span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.specialtyCode"
                        placement="top-start"
                      >
                        <span> 代码：{{ item.specialtyCode }}</span>
                      </el-tooltip>
                    </span>
                  </div>
                  <div style="width: 39%; overflow: hidden">
                    <p>
                      {{ item.disciplineAmbit }}
                    </p>
                    <span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="`上年最低分数：${item.scoreLowest}`"
                        placement="top-start"
                      >
                        <span> 上年最低分数：{{ item.scoreLowest }}</span>
                      </el-tooltip>
                    </span>
                  </div>
                  <!-- <div class="f-row f-sb"></div>
                <div class="f-sb"></div> -->
                </div>
                <div>
                  <el-button @click="choose(item, index)" round>{{
                    item.hasAdd == true ? "已填报" : "填报"
                  }}</el-button>
                </div>
              </div>
            </el-card>
          </div>
        </el-tab-pane>
        <el-tab-pane
          :label="`保底（${schoolList.b.length || 0}）`"
          name="third"
        >
          <div class="f-sb df-wrap">
            <el-card
              shadow="hover"
              style="width: 440px; margin-bottom: 30px"
              v-for="(item, index) in schoolList.b"
              :key="index"
            >
              <div class="f-sb school">
                <div style="width: 80%" class="f-sb cont-1">
                  <div style="width: 30%; overflow: hidden" class="">
                    <p style="font-weight: bold">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.collegeName"
                        placement="top-start"
                      >
                        <span>{{ item.collegeName }}</span>
                      </el-tooltip>
                    </p>
                    <!-- <p>{{ item.specialtyName }}</p> -->
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.specialtyName"
                      placement="top-start"
                    >
                      <span> {{ item.specialtyName }}</span>
                    </el-tooltip>
                  </div>
                  <div style="width: 30%; overflow: hidden">
                    <p>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.collegeCode"
                        placement="top-start"
                      >
                        <span> 代码:{{ item.collegeCode }}</span>
                      </el-tooltip>
                    </p>
                    <span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.specialtyCode"
                        placement="top-start"
                      >
                        <span> 代码：{{ item.specialtyCode }}</span>
                      </el-tooltip>
                    </span>
                  </div>
                  <div style="width: 39%; overflow: hidden">
                    <p>
                      {{ item.disciplineAmbit }}
                    </p>
                    <span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="`上年最低分数：${item.scoreLowest}`"
                        placement="top-start"
                      >
                        <span> 上年最低分数：{{ item.scoreLowest }}</span>
                      </el-tooltip>
                    </span>
                  </div>
                  <!-- <div class="f-row f-sb"></div>
                <div class="f-sb"></div> -->
                </div>
                <div>
                  <el-button @click="choose(item, index)" round>{{
                    item.hasAdd == true ? "已填报" : "填报"
                  }}</el-button>
                </div>
              </div>
            </el-card>
          </div></el-tab-pane
        >
      </el-tabs>
      <div class="po">
        <span>录取批次：</span>
        <el-select v-model="formdata.admitBatch" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.batchId"
            :label="item.batch"
            :value="item.batch"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 下方 -->
    <div class="right">
      <div
        v-for="(item, index) in hasschoolList"
        :style="{ marginBottom: index == 0 ? '20px' : 0 }"
        :key="index"
      >
        <template v-if="item.schlist.length > 0">
          <p style="margin-bottom: 20px">{{ item.batch }}</p>
        </template>

        <div class="f-sb df-wrap">
          <el-card
            class=""
            shadow="hover"
            style="width: 450px; margin-bottom: 20px"
            v-for="(item1, index1) in item.schlist"
            :key="index1"
          >
            <div class="f-row f-sb">
              <span class="round">
                {{ index1 + 1 }}
              </span>
              <p>{{ item1.collegeName }}</p>
              <span style="color: #666666"> {{ item1.specialtyName }}</span>
              <div>
                <span style="color: #666666">录取率：</span>
                <span
                  :class="{
                    high: item.admitRate * 100 > 70,
                    middle: 50 < item.admitRate * 100 < 70,
                    low: item.admitRate * 100 < 50,
                  }"
                  >{{ (item1.admitRate * 100).toFixed(1) }}%</span
                >
              </div>
              <img
                @click="deleteB(item1, index1)"
                style="width: 28px; height: 28px; float: right; cursor: pointer"
                src="@/assets/third/删除.png"
                alt=""
              />
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <div style="width: 200px; margin: 0 auto">
      <el-button @click="product" type="primary"> 生成志愿</el-button>
    </div>
  </div>
</template>
<script>
import {
  deletezy, //删除志愿
  getPageByStudentId, //志愿列表
  getOneProvince,
  submitAnalog, //志愿模拟填报
  saveAspiration, //生成志愿
} from "@/api/thirdPage";
export default {
  name: "manual",
  props: ["formdata1"],
  data() {
    return {
      flag: 0,
      // obj: {},
      // obj2: {},
      list: [],
      hasschoolList: [],
      schoolList: {
        b: [
          {
            collegeName: "北京大学",
            specialtyName: "物理系",
            collegeCode: "111123",
            specialtyCode: "22222",
            disciplineAmbit: "全部",
            scoreLowest: "650",
          },
          {
            collegeName: "北京大学",
            specialtyName: "物理系",
            collegeCode: "111123",
            specialtyCode: "22222",
            disciplineAmbit: "全部",
            scoreLowest: "650",
          },
        ],
        c: [
          {
            collegeName: "北京大学",
            specialtyName: "物理系",
            collegeCode: "111123",
            specialtyCode: "22222",
            disciplineAmbit: "全部",
            scoreLowest: "650",
          },
          {
            collegeName: "北京大学",
            specialtyName: "物理系",
            collegeCode: "111123",
            specialtyCode: "22222",
            disciplineAmbit: "全部",
            scoreLowest: "650",
          },
        ],
        w: [
          {
            admitBatch: "本科提前批",
            collegeName: "北京大学",
            specialtyName: "物理哇衣服哇衣服我刚又发哇",
            collegeCode: "111123",
            specialtyCode: "22222",
            disciplineAmbit: "全部",
            scoreLowest: "650",
          },
          {
            collegeName: "北京大学",
            specialtyName: "物理系",
            collegeCode: "111123",
            specialtyCode: "22222",
            disciplineAmbit: "全部",
            scoreLowest: "650",
          },
        ],
      },
      admitBatch: "",
      active: "1",
      activeName: "first",
      hasAdd: true,
      tabs: 1,
      options: [],
      formdata: JSON.parse(this.$route.query.formdata),
      tableData: [
        {
          date: 11,
          date1: 11,
          date2: 11,
          date3: 11,
          date4: 11,
        },
        {
          date: 11,
          date1: 11,
          date2: 11,
          date3: 11,
          date4: 11,
        },
      ],
    };
  },
  watch: {
    "formdata.admitBatch": {
      handler(val) {
        console.log(val);
        if (val) {
          this.getDetail();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.flag = 2;
    this.getadmitBatch();
  },
  methods: {
    // 选择要填报的学校和专业
    choose(school, index) {
      console.log(school, index);

      this.hasschoolList.forEach((item) => {
        console.log(1111);
        if (item.batch == this.formdata.admitBatch) {
          console.log(1111);

          if (item.schlist.length >= item.majorNum * item.voNum) {
            console.log(222222);
            this.$message.error(
              "超过了最大可可添加数，如果一点要填报的话，请先删除一些志愿"
            );
            return;
          }
          let res = item.schlist.every((item1) => {
            console.log(222);

            if (
              item1.collegeCode == school.collegeCode &&
              item1.specialtyCode == school.specialtyCode
            ) {
              this.$message.error("该志愿已填报");
              return false;
            } else {
              return true;
            }
          });
          if (res) {
            console.log(3333);
            school.hasAdd = true;
            // let arr = [];
            // arr.push(school);
            // item.schlist = arr;
            item.schlist.push(school);
            // item = Object.assign([], item, {});

            this.flag = 11;
          }
        }
        item.schlist.sort();
      });

      // this.hasschoolList.push(item);
    },
    async getadmitBatch() {
      // this.$parent.formdata;
      console.log(this.$parent);
      let res = await getOneProvince({
        year: new Date().getFullYear(),
        areaProvinceId: this.formdata.examProvince.id,
      });
      console.log(res);
      if (res.code == 1) {
        this.options = res.data.batchs;
        // this.options.forEach((item, index) => {
        //   this.hasschoolList.push({
        //     batch: item.batch,
        //     schlist: [],
        //   });
        // });

        this.formdata = Object.assign({}, this.formdata, {
          admitBatch: this.options[0].batch,
        });
      }
    },

    async getDetail(value) {
      // let data=JSON.parse('')
      let res = await submitAnalog({
        ...this.formdata,
      });
      // });
      console.log(res);
      if (res.code == 1) {
        // this.schoolList = res.data;
        if (this.hasschoolList.length == 0) {
          this.hasschoolList = res.data.province.batchs;

          this.hasschoolList.forEach((item) => {
            item.schlist = [];
          });
        }
        // schitem(this.schoolList.b);
        // schitem(this.schoolList.c);
        // schitem(this.schoolList.w);
        function schitem(val) {
          val.forEach((item) => {
            switch (item.disciplineAmbit) {
              case 0:
                item.disciplineAmbit = "全部";
                break;
              case 1:
                item.disciplineAmbit = "综合";
                break;
              case 2:
                item.disciplineAmbit = "理工";
                break;
              case 3:
                item.disciplineAmbit = "文史";
                break;
              case 4:
                item.disciplineAmbit = "师范";
                break;
              case 5:
                item.disciplineAmbit = "教育";
                break;
              case 6:
                item.disciplineAmbit = "财经";
                break;
              case 7:
                item.disciplineAmbit = "艺术";
                break;
              case 8:
                item.disciplineAmbit = "农林";
                break;
              case 9:
                item.disciplineAmbit = "军事";
                break;
              case 10:
                item.disciplineAmbit = "民族";
                break;
              case 11:
                item.disciplineAmbit = "政法";
                break;
              case 12:
                item.disciplineAmbit = "医药";
                break;
              case 13:
                item.disciplineAmbit = "体育";
                break;
              case 14:
                item.disciplineAmbit = "预科";
                break;
              case 20:
                item.disciplineAmbit = "其他";
                break;
            }
          });
        }
      }
    },
    //   删除选择
    async deleteB(item1, index1) {
      console.log(item1, index1);
      this.hasschoolList.forEach((item) => {
        console.log(item);
        if (item.batch == item1.admitBatch) {
          item.schlist.splice(index1, 1);
          this.flag = 12;
        }
      });
      // let res = await deletezy();
      // if (res.code == 1) {
      //   this.$message.success("删除成功");
      //   this.getDetail();
      // }
    },
    // 生成志愿
    async product() {
      this.hasschoolList.map((item) => {
        if (item.schlist.length > 0) {
          let obj = { ...item };
          return item.schlist.map((item1) => {
            this.list.push({ ...item1, ...obj });
          });
        }
      });
      if (this.list.length > 0) {
      } else {
        this.$message.error("您暂未选择志愿");
      }
      // let res = await saveAspiration();
    },
    // 切换tabs
    handleClick(tab, event) {
      console.log(tab.index);

      console.log(event);
      //   this.activeName = tab;
    },
  },
};
</script>
<style lang="less" scoped>
.f-sb {
  align-items: center;
}
.right {
  p {
    color: #333333;
    font-size: 16px;
  }
  // width: 450px;
  .f-col {
    margin: 0 60px 0 20px;
  }

  .f-row {
    align-items: center;
  }
  .round {
    border-radius: 50%;
    display: inline-block;
    color: white;
    // border: 1px solid black;
    height: 20px;
    line-height: 20px;
    text-align: center;

    background: #1ab394;
    width: 20px;
  }
  .tablebg {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.9)
    );
    width: 100%;
    position: absolute;
    top: 0;
  }
  .high {
    color: #008080;
  }
  .middle {
    color: orange;
  }
  .low {
    color: red;
  }
}
/deep/.el-radio-button__inner {
  color: #666666 !important;
  width: 120px;
  height: 28px;
  line-height: 28px;
  padding: 0px !important;
  margin: 5px !important;
}
/deep/.el-radio-button:first-child .el-radio-button__inner {
  border-left: 0px !important;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #1ab394 !important;
  color: #fff !important;
}
/deep/.el-radio-button__inner {
  border: 0px !important;
}
/deep/ button.el-button.el-button--default.is-round {
  width: 84px;
  height: 28px;
  background-color: #1ab394;
  line-height: 4px;

  color: white;
  span {
  }
}
/deep/ .el-tab-pane {
  max-height: 1200px;
  overflow: auto;
}
.cont-1 {
  p {
    margin-bottom: 10px;
  }
  span {
  }
}
.po {
  position: absolute;
  top: 0;
  right: 0;
}
.nav-1 {
  span {
    font-size: 14px;
  }
  p {
    color: #333333;
    font-size: 16px;
  }
}
.school {
  span {
    height: 20px;
    display: inline-flex;
  }
}

.el-tabs.el-tabs--top {
  width: 100%;
}
/deep/ .el-button--primary {
  background-color: #1ab394;
  color: white;
  padding: 10px 46px;
}
/deep/.el-tabs__nav-wrap::after {
  content: "";
  display: none;
}
/deep/.el-tabs__active-bar {
  background-color: #1ab394;
}
/deep/ .el-tabs__item.is-active {
  color: #1ab394;
}
/deep/ .el-tabs__item:hover {
  color: #1ab394;
}
</style>
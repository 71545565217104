import axios from '@/util/request'
//智能填报

//GET /com/had-login/sys/sys-province/getOneProvince
// 根据年份和省份(地区)id查询单个
export const getOneProvince = (params) => {
  return axios({
    url: '/com/had-login/sys/sys-province/getOneProvince',
    method: 'get',
    params
  })
}

// POST /com/had-login/mobile/admission-info/getAccumulateByScore
// 根据分数查位次(分数传参给scoreMin)
export const getAccumulateByScore = (data) => {
  return axios({
    url: '/com/had-login/mobile/admission-info/getAccumulateByScore',
    method: 'post',
    data
  })
}

// POST /com/had-login/mobile/aspiration-analog/selectAnalog
// （先）个性化志愿填报
export const selectAnalog = (data) => {
  return axios({
    url: '/com/had-login/mobile/aspiration-analog/selectAnalog',
    method: 'post',
    data
  })

}

// POST /com/had-login/mobile/aspiration-analog/saveAspiration
// （后）生成模拟志愿
export const saveAspiration = (data) => {
  return axios({
    url: '/com/had-login/mobile/aspiration-analog/saveAspiration',
    method: 'post',
    data
  })
}

//Get /mobile/specialty-info/getSpecialtyHotList
// 意向专业 关键词 （热门专业）
export const getSpecialtyHotList = (params) => {
  return axios({
    url: '/mobile/specialty-info/getSpecialtyHotList',
    method: 'get',
    params
  })
}
// 热门专业
// 接口：/com/sys-keyword/getList
// 参数：type=1

export const getListKeyword = (params) => {
  return axios({
    url: '/com/sys-keyword/getList',
    method: 'get',
    params
  })
}

// GET /com/had-login/mobile/aspiration-analog/getDetail
// 我的模拟志愿详情
export const getDetail = (params) => {
  return axios({
    url: '/com/had-login/mobile/aspiration-analog/getDetail',
    method: 'get',
    params
  })
}


// POST /com/had-login/mobile/aspiration-analog/submitAnalog
// 智能填报一键生成志愿
export const submitAnalog = (data) => {
  return axios({
    url: '/com/had-login/mobile/aspiration-analog/submitAnalog',
    method: 'post',
    data
  })
}

// /com/had-login/mobile/user-info/updateUseState
// 标记当前服务正在使用（type:1兴趣测评 2模拟志愿 3分数查询）
export const updateUseState = (params) => {
  return axios({
      url: '/com/had-login/mobile/user-info/updateUseState',
      method: 'get',
      params
  })
}

// /com/had-login/mobile/user-info/getUserQueryCount
// 查询用户剩余服务次数或期限（type:1兴趣测评 2模拟志愿 3分数查询）
export const getUserQueryCount = (params) => {
  return axios({
      url: '/com/had-login/mobile/user-info/getUserQueryCount',
      method: 'get',
      params
  })
}
<template>
  <div>
    <el-form :model="formdata" :rules="rules" ref="formdata" label-width="80px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="高考成绩">
            <el-input v-model="formdata.totalScore" @change="totalScoreChange"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="高考位次" prop="accumulate">
            <el-input v-model="formdata.accumulate"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="科别类型">
            <el-select v-model="formdata.disciplineType" placeholder="请选择" style="width: 100%"
              @change="disciplineTypeChange">
              <el-option v-for="item in divisionType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="志愿批次" prop="admitBatch">
            <el-select v-model="formdata.admitBatch" placeholder="请选择" style="width: 100%" @change="admitBatchChange">
              <el-option v-for="item in volunteerBatch" :key="item.batchId" :label="item.batch" :value="item.batchId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="意向省份" prop="examProvinces">
        <el-checkbox-group v-model="formdata.examProvinces" @change="changeExamProvince" class="header">
          <div :class="{ active: isUnlimited }" class="is-unlimited" @click="isUnlimited = !isUnlimited">
            不限
          </div>
          <el-checkbox-button :label="item" v-for="item in provinceList" :key="item.id">{{ item.name
          }}</el-checkbox-button>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="排除省份" prop="examProvincesNot">
        <div class="align-start">
          <!-- <el-checkbox-button v-model="isUnlimited1" label="" :checked="true"
            >不限</el-checkbox-button
          > -->
          <el-checkbox-group v-model="formdata.examProvincesNot" @change="changeExamProvinceNot" class="header">
            <div :class="{ active: isUnlimited1 }" class="is-unlimited" @click="isUnlimited1 = !isUnlimited1">
              不限
            </div>
            <el-checkbox-button :label="item" v-for="item in provinceList" :key="item.id">{{ item.name
            }}</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item label="意向专业" prop="specialtyNames ">
        <el-input v-model.number="formdata.specialtyNames" clearable placeholder="请输入专业名字、关键字（多个用,分割）"></el-input>
        <div class="label-box">
          <div :class="item.ischoose ? 'label-item1' : 'label-item'" v-for="(item, index) in specialtyNamesList"
            :key="index" @click="selectLable(item)">
            {{ item.name }}
          </div>
        </div>
      </el-form-item>
      <el-form-item label="" prop="">
        <div class="option-btn">
          <div class="btn-box" @click="smartSolution">智能方案</div>
          <div class="btn-box" @click="toPersonalizedPlan">个性化方案</div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getProvinceList } from "@/api/index.js";
import manual from "./Intelligent/manual.vue";
import {
  getOneProvince,
  getAccumulateByScore,
  getListKeyword,
  getUserQueryCount
} from "@/api/IntelligentReporting";
import { mapState } from "vuex";
export default {
  components: { manual },
  name: "Intelligentreporting",
  data() {
    return {
      isfirst: "1",
      activep: 0,
      dialogVisible: false,
      activeNames: "",
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      activeName: "",
      isUnlimited: true,
      isUnlimited1: true,
      formdata: {
        totalScore: null,
        accumulate: null,
        disciplineType: null,
        admitBatch: null,
        examProvinces: [],
        examProvincesNot: [],
        specialtyNames: "",
      },
      rules: {},
      divisionType: [], //科别类型
      volunteerBatch: [], //志愿批次
      provinceList: [], //省市
      currentYear: "", //当前年
      province: "", //报考省份
      specialtyNamesList: [],
    };
  },
  computed: {
    ...mapState(["studentInfo"]),
  },
  watch: {
    // 意向省份不限
    isUnlimited(val) {
      if (val) {
        this.formdata.examProvinces = [];
      }
    },
    // 排除省份不限
    isUnlimited1(val) {
      if (val) {
        this.formdata.examProvincesNot = [];
      }
    },
  },
  mounted() {
    console.log(this.studentInfo.province, "this.studentInfo.province");
    this.currentYear = new Date().getFullYear();
    let studentInfo = this.$store.studentInfo || {};
    console.log(this.studentInfo, "this.studentInfo");
    this.formdata.totalScore = this.studentInfo.score || null;
    this.formdata.disciplineType = this.studentInfo.disciplineType;
    this.formdata = { ...this.formdata };
    this.totalScoreChange();
    this.province = studentInfo.province || "";
    this.getProvinceList();
    this.getSpecialtyNames();
    setTimeout(() => {
      this.obtainProvinceInfo(new Date().getFullYear());
    }, 100);
  },
  methods: {
    //   省份
    getProvinceList() {
      getProvinceList({}).then((res) => {
        if (res.code == 1) {
          this.provinceList = res.data || [];
          this.provinceList = this.provinceList.filter((item) => {
            console.log(item.name);
            return (
              item.name != "台湾省" &&
              item.name != "香港特别行政区" &&
              item.name != "澳门特别行政区"
            );
          });
        }
      });
    },

    // 关键词
    getSpecialtyNames() {
      getListKeyword({ type: 1 }).then((res) => {
        if (res.code == 1) {
          if (res.data && res.data.length > 0) {
            res.data.forEach((item) => {
              item.ischoose = false;
            });
          }
          this.specialtyNamesList = res.data || [];
        }
      });
    },
    // 科别类型  志愿批次
    obtainProvinceInfo(year) {
      getOneProvince({
        year: year,
        areaProvinceId: this.studentInfo.province,
        // areaProvinceId: "450000",
      }).then((res) => {
        this.divisionType = [];
        if (res.code == 1) {
          let resData = res.data || {};

          if (new Date().getTime() < new Date(resData.planDate.replace(/-/g, '/')).getTime()) {
            this.obtainProvinceInfo(year - 1)
          } else {
            if (resData.batchs) {
              this.volunteerBatch = resData.batchs.filter(function (batch, index, arr) {
                return batch.scoreIsInit == 1
              })
            }
          }

          if (resData.pattern == 1) {
            this.divisionType.push({
              value: 0,
              label: "不分文理",
            });
          } else if (resData.pattern == 2) {
            this.divisionType.push({
              value: 3,
              label: "物理类",
            });
            this.divisionType.push({
              value: 4,
              label: "历史类",
            });
          } else if (resData.pattern == 3) {
            this.divisionType.push({
              value: 1,
              label: "理科",
            });
            this.divisionType.push({
              value: 2,
              label: "文科",
            });
          }
        }
      });
    },

    //高考成绩改变
    totalScoreChange() {
      // let reg = /(^([1-9]\d*)(\.\d{1,2})?$)|(^0\.\d{1,2}?$)/g;
      // if(!reg.test(this.formdata.totalScore)){
      //   this.$message.warning('请输入大于0的数字');
      //   return ;
      // }
      if (
        this.formdata.totalScore &&
        (this.formdata.disciplineType || this.formdata.disciplineType === 0)
      ) {
        this.getAccumulate(new Date().getFullYear());
      }
    },

    // 科别类型
    disciplineTypeChange(item) {
      // console.log(item, "222222222");
      // this.getAccumulate();

      let currentItem = this.divisionType.filter((v) => v.value == item);

      this.formdata.disciplineTypeName = currentItem[0].label;
      if (
        this.formdata.totalScore != null &&
        this.formdata.disciplineType != null
      ) {
        this.getAccumulate(new Date().getFullYear());
      }
    },

    // 根据成绩获取位次
    getAccumulate(year) {
      getAccumulateByScore({
        // "admitBatch": ,
        disciplineType: this.formdata.disciplineType,
        province: {
          id: this.studentInfo.province,
          name: this.studentInfo.residenceProvince,
        },
        scoreMin: this.formdata.totalScore,
        year: year,
      }).then((res) => {
        if (res.code == 1) {
          let resData = res.data || null;

          if (resData) {
              this.$set(this.formdata, "accumulate", resData);
						} else {
							if (year == new Date().getFullYear()) {
								this.getAccumulate(new Date().getFullYear() - 1)
							} 
						}
        }
      });
    },

    // 志愿批次
    admitBatchChange(item) {
      let batchItem = this.volunteerBatch.filter((v) => v.batchId == item);
      this.$store.commit("setVoPa", batchItem[0].voPa);
      this.$store.commit("setVoNum", batchItem[0].voNum);
      this.formdata.admitBatchName = batchItem[0].batch;
    },

    // 意向省份
    changeExamProvince(item) {
      if (item && item.length > 0) {
        this.isUnlimited = false;
      } else {
        this.isUnlimited = true;
      }
    },

    // 排除省份
    changeExamProvinceNot(item) {
      if (item && item.length > 0) {
        this.isUnlimited1 = false;
      } else {
        this.isUnlimited1 = true;
      }
    },

    // 选择关键词
    selectLable(item) {
      this.specialtyNamesList.forEach((el) => {
        if (el.name == item.name) {
          if (el.ischoose) {
            el.ischoose = false;
          } else {
            el.ischoose = true;
          }
        }
      });
      let specialtyNames = this.formdata.specialtyNames;
      if (this.formdata.specialtyNames) {
        if (!specialtyNames.includes(item.name)) {
          specialtyNames += "," + item.name;
        } else {
          specialtyNames = specialtyNames.replace(item.name, "");
          if (specialtyNames[specialtyNames.length - 1] == ",") {
            specialtyNames = specialtyNames.substr(
              0,
              specialtyNames.length - 1
            );
          }
        }
      } else {
        if (!specialtyNames.includes(item.name)) {
          specialtyNames += item.name;
        }
      }
      this.$set(this.formdata, "specialtyNames", specialtyNames);
    },

    judgmentRequired() {
      if (!this.formdata.totalScore) {
        this.$message.warning("请输入高考成绩");
        return false;
      }

      if (this.formdata.disciplineType == null) {
        this.$message.warning("请选择科别类型");
        return false;
      }

      if (!this.formdata.admitBatch) {
        this.$message.warning("请选择志愿批次");
        return false;
      }

      if (!this.formdata.accumulate) {
        this.$message.warning("请输入高考位次");
        return false;
      }

      if (
        this.formdata.examProvinces.length < 5 &&
        this.formdata.examProvinces.length > 0
      ) {
        console.log(this.formdata.examProvinces);
        this.$message.warning("意向省份至少需要选择5个");
        return false;
      }
      return true;
    },

    // 个性化方案
    toPersonalizedPlan() {
      if (this.judgmentRequired()) {
        getUserQueryCount({ type: 2 }).then((res) => {
          if (res.code == 1) {
            let data = res.data || {};
            if (data.validityType == 1) {
              if (data.usageCount > 0) {
                this.$store.commit("setFormObj", this.formdata);
                this.$router.push({
                  path: "/intelligentReporting/personalizedPlan",
                });
              } else {
                this.$message.error("次数不足，请前往OK志愿小程序购买");
              }
            } else {
              let validDate = new Date(data.validityTime.replace(/-/, "/"));
              if (new Date().getTime() <= validDate.getTime()) {
                this.$store.commit("setFormObj", this.formdata);
                this.$router.push({
                  path: "/intelligentReporting/personalizedPlan",
                });
              } else {
                this.$message.error("有效期已过期，请前往OK志愿小程序购买");
              }
            }
          }
        });
      }
      //
    },

    // 智能方案
    smartSolution() {
      if (this.judgmentRequired()) {
        getUserQueryCount({ type: 2 }).then((res) => {
          if (res.code == 1) {
            let data = res.data || {};
            if (data.validityType == 1) {
              if (data.usageCount > 0) {
                this.$store.commit("setFormObj", this.formdata);
                this.$router.push({
                  path: "/intelligentReporting/planDetail",
                  query: {
                    intelligent: 1,
                  },
                });
              } else {
                this.$message.error("次数不足，请前往OK志愿小程序购买");
              }
            } else {
              let validDate = new Date(data.validityTime.replace(/-/, "/"));
              if (new Date().getTime() <= validDate.getTime()) {
                this.$store.commit("setFormObj", this.formdata);
                this.$router.push({
                  path: "/intelligentReporting/planDetail",
                  query: {
                    intelligent: 1,
                  },
                });
              } else {
                this.$message.error("有效期已过期，请前往OK志愿小程序购买");
              }
            }
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.option-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  .btn-box {
    width: 300px;
    height: 50px;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    line-height: 50px;

    &:first-child {
      color: #fff;
      background-color: #1ab394;
    }

    &:last-child {
      color: #1ab394;
      background: #caf8e4;
    }
  }
}

.align-start {
  display: flex;
  justify-content: flex-start;
}

.label-box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;

  .label-item {
    padding: 4px 10px;
    line-height: initial;
    // border: 1px solid #a6a9ad;
    border-radius: 5px;
    color: #a6a9ad;
    background: #e6e7e8;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .label-item1 {
    padding: 4px 10px;
    line-height: initial;
    border-radius: 5px;
    // border: 1px solid #a6a9ad;
    color: #fff;
    background: #1ab394;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
}

/deep/.is-unlimited {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 28px;
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  border-radius: 4px 0 0 4px;
  box-shadow: none;
  color: #666666;
  margin: 5px;
  cursor: pointer;

  &.active {
    background: #1ab394;
    color: #fff;
  }
}

/deep/.el-radio-button__inner,
/deep/.el-checkbox-button__inner {
  color: #666666 !important;
  width: 120px;
  height: 28px;
  line-height: 28px;
  padding: 0px !important;
  margin: 5px !important;
}

/deep/.el-radio-button:first-child .el-radio-button__inner {
  border-left: 0px !important;
}

/deep/.el-checkbox-button:first-child .el-checkbox-button__inner {
  border-left: 0px !important;
}

/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-color: #1ab394 !important;
  color: #fff !important;
}

/deep/.el-checkbox-button__original:checked+.el-checkbox-button__inner {
  background-color: #1ab394 !important;
  color: #fff !important;
}

/deep/.el-radio-button__inner,
/deep/.el-checkbox-button__inner {
  border: 0px !important;
}

/deep/.el-button--primary,
/deep/.el-input-group__append {
  color: #fff;
  background-color: #1ab394;
  border-color: #1ab394;
}

.el-button:hover {
  color: #fff;
  background-color: #1ab394;
  border-color: #1ab394;
}
</style>
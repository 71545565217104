import axios from '@/util/request'
//高考动态

// GET /mobile/expert-info/getExpertDetail
// 查看专家详情
export const getExpertDetail = (params) => {
    return axios({
        url: '/mobile/expert-info/getExpertDetail',
        method: 'get',
        params
    })

}

// POST /com/had-login/mobile/aspiration-analog/submitAnalog
// （先）志愿模拟填报
export const submitAnalog = (data) => {
        return axios({
            url: '/com/had-login/mobile/aspiration-analog/submitAnalog',
            method: 'post',
            data
        })

    }
    // POST /com/had-login/mobile/aspiration-analog/saveAspiration
    // （后）生成模拟志愿

export const saveAspiration = (data) => {
    return axios({
        url: '/com/had-login/mobile/aspiration-analog/saveAspiration',
        method: 'post',
        data
    })

}

// GET /com/had-login/mobile/aspiration-analog/delete
// 删除模拟志愿

export const deletezy = (params) => {
        return axios({
            url: '/com/had-login/mobile/aspiration-analog/delete',
            method: 'GET',
            params
        })

    }
    // POST /com/had-login/mobile/aspiration-analog/getPageByStudentId
    // 我的模拟志愿列表
export const getPageByStudentId = (data) => {
        return axios({
            url: '/com/had-login/mobile/aspiration-analog/getPageByStudentId',
            method: 'POST',
            data
        })

    }
    // GET /com/had-login/mobile/aspiration-analog/hasActive
    // 查询是否已购买并激活模拟志愿（返回0表示没有，1表示已激活）
export const hasActive = (params) => {
        return axios({
            url: '/com/had-login/mobile/aspiration-analog/hasActive',
            method: 'GET',
            params
        })

    }
    // POST /mobile/specialty-info/getCategoryList
    // 获取所有学科门类列表（传参传空对象{}）

export const getCategoryList = (data) => {
    return axios({
        url: '/mobile/specialty-info/getCategoryList',
        method: 'POST',
        data
    })

}

// POST /mobile/specialty-info/getDisciplineList
// 根据学科门类名称获取专业类列表（传参传categoryId）
export const getDisciplineList = (data) => {
        return axios({
            url: '/mobile/specialty-info/getDisciplineList',
            method: 'POST',
            data
        })

    }
    // GET /mobile/specialty-info/getSpecialtyTree
    // 获取所有专业树形结构（传name搜索,且只能搜专业(第三级)）
    // export const getSpecialtyTree = (params) => {
    //     return axios({
    //         url: '/mobile/specialty-info/getSpecialtyTree',
    //         method: 'get',
    //         params
    //     })

// }
// POST /mobile/specialty-info/getSpecialtyList
// 根据学科类名称获取专业列表（传参传disciplineId）
export const getSpecialtyList = (data) => {
        return axios({
            url: '/mobile/specialty-info/getSpecialtyList',
            method: 'POST',
            data
        })

    }
    // GET /com/had-login/sys/sys-province/getOneProvince
    // 根据年份和省份(地区)id查询单个
export const getOneProvince = (params) => {
    return axios({
        url: '/com/had-login/sys/sys-province/getOneProvince',
        method: 'GET',
        params
    })

}